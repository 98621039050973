import Accordion from "./accordion";
import AlternatingList from "./alternatingList/alternatingList";
import Arrow from "./arrow";
import Banner from "./banner";
import Columns from "./columns";
import ColumnsWithSeparators from "./columnsWithSeparators";
import Comparison from "./comparison";
import Contact from "./contact";
import ContactDrReferrals from "./contactDrReferrals";
import ContactCareers from "./contactCareers";
import Cta from "./cta";
import Goals from "./goals";
import Gen4Agenda from "./gen4Agenda";
import Gen4Awards from "./gen4Awards";
import Hero from "./hero";
import HeroWithCountdown from "./heroWithCountdown";
import HeroWithLogo from "./heroWithLogo";
import ImageWithHeadingAndText from "./imageWithHeadingAndText";
import ImagesAndCaption from "./imagesAndCaption";
import LogoCarousel from "./logoCarousel/logoCarousel";
import News from "./news";
import Overview from "./overview/overview";
import Partners from "./partners";
import People from "./people";
import Podcast from "./podcast";
import Pediatric from "./pediatric";
import StaffCarousel from "./staffCarousel";
import AwardsCarousel from "./awardsCarousel";
import Tabs from "./tabs";
import TabsWithRichText from "./tabsWithRichText";
import TextAndButton from "./textAndButton";
import TextAndImage from "./textAndImage";
import VideoHero from "./videoHero";

export default {
  "main-accordion": Accordion,
  "main-alternating-list": AlternatingList,
  "main-arrow": Arrow,
  "main-banner": Banner,
  "main-columns": Columns,
  "main-columns-with-separators": ColumnsWithSeparators,
  "main-comparison": Comparison,
  "main-contact": Contact,
  "main-contactdrreferrals": ContactDrReferrals,
  "main-contactcareers": ContactCareers,
  "main-cta": Cta,
  "main-goals": Goals,
  "main-gen4agenda": Gen4Agenda,
  "main-gen4awards": Gen4Awards,
  "main-hero": Hero,
  "main-hero-with-countdown": HeroWithCountdown,
  "main-hero-with-logo": HeroWithLogo,
  "main-image-with-heading-and-text": ImageWithHeadingAndText,
  "main-images-and-caption": ImagesAndCaption,
  "main-logo-carousel": LogoCarousel,
  "main-news": News,
  "main-overview": Overview,
  "main-partners": Partners,
  "main-people": People,
  "main-podcast": Podcast,
  "main-pediatric": Pediatric,
  "main-staff-carousel": StaffCarousel,
  "main-awards-carousel": AwardsCarousel,
  "main-tabs": Tabs,
  "main-tabs-with-rich-text": TabsWithRichText,
  "main-text-and-button": TextAndButton,
  "main-text-and-image": TextAndImage,
  "main-video-hero": VideoHero,
};
